<template>
  <div class="container">
    <div class="btn" @click="dialogVisible = true">Send A Message</div>
    <el-empty
      v-if="!datas.length"
      description="No Messages Yet"
      :image-size="220"
      image="https://mainbucket.learningfirst.cn/noMessage.png"
    ></el-empty>
    <div
      v-for="(item, index) in datas"
      :key="index"
      class="item"
    >
      <div class="main">
        <div class="name" style="color: #0e4890;" v-if="item.type=='general'">
          <span>Sent</span> <span class="name-right">{{getTime(item.create_time*1000)}}</span>
        </div>
        <div class="name" style="color: #ffb414;" v-else>
          <span>Reply</span> <span class="name-right">{{getTime(item.create_time*1000)}}</span>
        </div>
        <div class="content">
          <div>{{item.message_content}}</div>
          <div style="margin-top: 20px;" v-if="item.reply_content">Reply:{{item.reply_content}}</div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="813px"
      height="831px"
      center
      :show-close="false"
      custom-class="dialog_class"
      close-on-click-modal
      close-on-press-escape
    >
      <div
        style="font-size: 30px;font-family: DDINAlternateRegular;font-weight: bold;color: #F65D4D;text-align: center;"
      >
        Send A Message
      </div>
      <div style="margin: 50px auto 0; width: 572px;">
        <el-input rows="15" v-model="content" placeholder="Please input your message" type="textarea"></el-input>
      </div>
      <span slot="footer" style="width: 475px; margin: 80px auto 0;">
<!--        <el-button type="primary" @click="dialogVisible = false">确认发送</el-button>-->
        <div
          style="width: 475px;height: 50px;background: #0E4890;border-radius: 50px;color: #FFFFFF;line-height:50px;text-align: center;margin: auto; cursor: pointer;"
          @click="sendMessage">Send</div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getMyMessages, sendMessage } from '../../api/index'
import { getUserId, getProject } from '../../utils/store'
import '../../assets/common/font.css'
export default {
  data () {
    return {
      datas: [],
      dialogVisible: false,
      content: ''
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    getTime (time) {
      var date = new Date(time)
      var y = date.getFullYear()
      var m = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
      var d = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
      return y + '.' + m + '.' + d
    },
    fetchData () {
      getMyMessages(getUserId(), getProject()).then((res) => {
        if (res.data.code === 0) {
          this.datas = res.data.data
        }
      })
    },
    sendMessage () {
      sendMessage(getUserId(), getProject(), this.content, 'general', '').then((res) => {
        if (res.data.code === 0) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
          this.fetchData()
          this.datas = res.data.data
          this.dialogVisible = false
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 11%;
  padding-left: 4%;
  padding-right: 4%;
  overflow-y: scroll;
  font-family: "DDINAlternateRegular";
  .btn {
    background: #0e4890;
    width: 170px;
    height: 34px;
    border-radius: 20px;
    line-height: 34px;
    text-align: center;
    color: #fff;
    position: absolute;
    top: 11%;
    right: 4%;
    cursor: pointer;
  }
  .item {
    display: flex;
    width: 100%;
    margin-bottom: 30px;
    cursor: pointer;
    img {
      display: block;
      height: 200px;
      width: 200px;
      border: 1px dotted #0e4890;
      border-radius: 15px;
      cursor: pointer;
    }
    .main {
      padding: 4px;
      margin-left: 16px;
      width: 100%;
      .name {
        color: #333333;
        font-weight: bold;
        font-size: 30px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: end;
        .name-right{
          font-size: 26px;
          font-weight: 400;
          color: #949494;
        }
      }
      .id {
        margin-top: 10px;
        color: #949494;
      }
      .tags {
        margin-top: 10px;
      }
      .tag {
        border: 1.4px solid #17396d;
        color: #17396d;
        padding: 0 8px;
        border-radius: 12px;
        margin-right: 8px;
        font-size: 14px;
      }
      .intro {
        margin-top: 16px;
        color: #949494;
        height: 40px;
        width: 100%;
        overflow: hidden;
      }

      .content {
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        border-bottom: 1px solid #707070;
        padding-bottom: 30px;
        width: 100%;
        margin-top: 20px;
      }
    }
  }

}
.dialog_class.el-dialog{
  border-radius: 15px!important;
  background: #FFFFFF;
}
</style>
